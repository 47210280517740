<template>
  <div class="ready-root">
    <div style="text-align: left">
      <div class="ready-text">进入正式考试前，请先进行设备检查，设备开启正常后方可进入考试：</div>
      <div style="color:white;margin-top:20px;">此准备室页面的设备开启仅为检测目的，正式进入考试界面后还需重新授权摄像头和完整屏幕分享</div>
      <div style="margin-top: 20px;display: flex;flex-direction: row;align-items: center;text-align: left">
        <img v-if="device_camera_status" src="../../assets/device_success.png" class="ready-img">
        <img v-if="!device_camera_status" src="../../assets/device_error.png" class="ready-img">
        <div class="ready-text" style="margin-left: 15px">摄像头开启正常</div>
        <div v-if="!device_camera_status" class="ready-restart" @click="checkDevice">重新开启</div>
      </div>
      <div style="margin-top: 20px;display: flex;flex-direction: row;align-items: center">
        <img v-if="device_screen_status" src="../../assets/device_success.png" class="ready-img">
        <img v-if="!device_screen_status" src="../../assets/device_error.png" class="ready-img">
        <div class="ready-text" style="margin-left: 15px">[整个屏幕*]分享开启正常</div>
        <div v-if="!device_screen_status" class="ready-restart" @click="checkDevice">重新开启</div>
      </div>
      <div style="margin-top: 20px;display: flex;flex-direction: row;align-items: center">
        <img v-if="idCheckSuccess" src="../../assets/device_success.png" class="ready-img">
        <img v-if="!idCheckSuccess" src="../../assets/device_error.png" class="ready-img">
        <div class="ready-text" style="margin-left: 15px">[身份证明**]拍照上传</div>
        <div v-if="idCheckSuccess" class="ready-restart" @click="showIdPicDialog=true">查看已上传照片</div>
        <div class="ready-restart" @click="doShowIdDialog">上传身份证明照片</div>
      </div>
      <div style="color:white;margin-top:50px;line-height:200%">* 考试必须分享[整个屏幕]，而不能仅分享单个窗口或浏览器页面
        <br>** 身份证明指任何带有本人照片和姓名信息的官方证明文件（包括但不限于身份证、护照、驾照、学校学生证、社保卡或其他证明文件）；
        <br>拍照时请将证件带有照片和姓名一面置于摄像头前保证照片名字可见（切勿离摄像头过近导致画面失焦模糊）；
        <br>证件照片仅做监考检查使用，考试核分结束后5个工作日后从服务器删除。
      </div>
      <div style="color:white;margin-top:20px;line-height:200%">注意：参加考试必须使用桌面电脑（PC或Mac），不支持手机和平板；必须使用Google
        Chrome浏览器，并打开摄像头和整个屏幕分享供系统进行监考。<br>不知道如何开启摄像头和整个屏幕分享？请点击查看<a
            href="https://mainbucket.learningfirst.cn/%E3%80%8A%E8%AE%BE%E5%A4%87%E6%8C%87%E5%8D%97%E3%80%8B.pdf"
            target="_blank"><span
            style="background-color:white;color:blue;margin-left:5px;padding:4px;border-radius:4px;">《设备指南》</span></a>
      </div>
      <div style="color:white;margin-top:20px;">考试期间不允许切屏（包括更换浏览器窗口、缩小浏览器、打开其他应用等），所有切屏均会后台记录并人工审查，切屏次数超过7次后将强行交卷。</div>
    </div>
    <div class="ready-go-exam" @click="goExam">
      进入考试
    </div>
    <canvas id="cameraCanvas" :width="cameraOptions.video.width" :height="cameraOptions.video.height"
            style="display: none;"></canvas>
    <canvas id="screenCanvas" :width="screenOptions.video.width" :height="screenOptions.video.height"
            style="display: none;"></canvas>

    <el-dialog :visible.sync="showUploadIdDialog" width="70%" title="拍照" :close-on-click-modal="false"
               :close-on-press-escape="false">
      <el-form>
        <div class="camera-box">
          <div style="display: flex;flex-direction: row">
            <div>
              <div style="margin-bottom: 10px">预览</div>
              <video id="id-video" :width="idVideoWidth" :height="idVideoHeight"></video>
            </div>
            <div style="margin-left: 10px">
              <div style="margin-bottom: 10px">照片</div>
              <canvas id="id-canvas" :width="idVideoWidth" :height="idVideoHeight"></canvas>
            </div>
          </div>
          <p class="camera-p">{{ '提示：请将身份证件居中，保证画面聚焦清晰，可见照片和姓名，按"拍照"键确认' }}</p>
          <div style="margin-top: 15px;display: flex;flex-direction: row">
            <el-button type="primary" @click="setImage">{{ imgSrc ? '重拍' : '拍照' }}</el-button>
            <el-button type="primary" @click="setFileUpload" v-if="imgSrc">上传</el-button>
          </div>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog :visible.sync="showIdPicDialog" width="40%">
      <div style="text-align: center">
        <el-image :src="pic_url"></el-image>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {checkIsUploadTest, uploadPic, uploadStudentIdCard, getStudentIdCard} from '../../api/index'
import {getProjectCode, getUserId} from "../../utils/store";


export default {
  name: "ExamReady",
  data() {
    return {
      imgSrc: '',
      showUploadIdDialog: false,
      cameraOptions: {
        audio: false,
        video: {width: 500, height: 500}
      },
      screenOptions: {
        audio: false,
        video: {width: 1280, height: 720}
      },
      timer: '',
      status: false,
      have_exam: false,
      device_camera_status: true,
      device_screen_status: true,
      template_id: this.$route.query.template_id,
      start_time: this.$route.query.start_time,
      event_type: this.$route.query.event_type,
      cameraVideo: null, // 摄像头视频容器
      screenVideo: null, // 屏幕视频容器
      cameraStream: null, // 摄像头视频流
      screenStream: null, // 屏幕视频流

      idCanvas: undefined,
      idVideo: undefined,
      idContext: undefined,
      idVideoWidth: 400,
      idVideoHeight: 300,
      idCheckSuccess: false,
      idStream: null,
      idVideoStream: null,
      showIdPicDialog:false,
      pic_url:''
    }
  },
  mounted() {
    this.checkStudentExam()
    this.startCheckOnline()
    this.checkDevice()
    this.checkStudentId()
  },
  beforeDestroy() {
    console.log('---beforeDestroy---')
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
    this.clearAllResource()
  },
  methods: {
    checkStudentId() {
      getStudentIdCard(getUserId(), this.template_id, getProjectCode()).then((res) => {
        var result = res.data.data
        if (result.pic_url) {
          this.idCheckSuccess = true
          this.pic_url = result.pic_url
        }
      })
    },
    doShowIdDialog() {
      this.imgSrc = ''
      this.showUploadIdDialog = true
      this.$nextTick(() => {
        this.getCompetence()
      })
    },
    setImage() {
      var _this = this
      // 点击，canvas画图
      _this.idContext.drawImage(_this.idVideo, 0, 0, _this.idVideoWidth, _this.idVideoHeight)
      // 获取图片base64链接
      var image = this.idCanvas.toDataURL('image/png')
      _this.imgSrc = image;
    },
    setFileUpload() {
      let param = new FormData()
      var time = new Date()
      var file_name = 'id_pic_' + time.getTime()
      var file = this.dataURLtoFile(this.imgSrc, file_name)
      param.append('function', 'uploadPic')
      param.append('file', file)
      uploadPic(param).then(res => {
        console.log(res)
        var result = res.data.data
        if (result) {
          this.doSavePic(result)
        } else {
          this.clearIdStream()
          this.$message.success("身份证上传失败，请重试！")
        }
      })
    },
    doSavePic(pic_url) {
      uploadStudentIdCard(getUserId(), this.template_id, getProjectCode(), pic_url).then((res) => {
        this.idCheckSuccess = true
        this.pic_url = pic_url
        this.$message.success("身份证上传成功")
        this.showUploadIdDialog = false
        this.clearIdStream()
      })
    },
    dataURLtoFile(dataUrl, fileName) {
      var arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, {type: mime});
    },
    getCompetence() {
      // 初始化的时候直接让为空
      this.imgSrc = '';
      var _this = this;
      this.idCanvas = document.getElementById('id-canvas')
      this.idContext = this.idCanvas.getContext('2d')
      this.idVideo = document.getElementById('id-video')
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {}
      }
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先获取现存的getUserMedia(如果存在)
          var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.getUserMedia
          // 有些浏览器不支持，会返回错误信息
          // 保持接口一致
          if (!getUserMedia) {
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
          }
          // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject)
          })
        }
      }
      var constraints = {
        audio: false,
        video: {width: this.idVideoWidth, height: this.idVideoHeight, transform: 'scaleX(-1)'}
      }
      navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
        _this.idStream = stream
        if ('srcObject' in _this.idVideo) {
          _this.idVideo.srcObject = stream
          _this.idVideoStream = stream
        } else {
          _this.idVideo.src = window.URL.createObjectURL(stream)
          _this.idVideoStream = stream
        }
        _this.idVideo.onloadedmetadata = function (e) {
          _this.idVideo.play()
        }
      }).catch(err => {
        console.log(err)
      })
    },
    goExam() {

      if (this.have_exam) {
        this.$message.error('您已完成考试')
        return
      }

      if (!this.status) {
        this.$message.error('当前您设备有问题，无法进入考试！')
        return
      }

      if(!this.idCheckSuccess){
        this.$message.error('请先上传身份证明!')
        return
      }

      var currentTime = new Date().getTime()

      if (this.event_type == 'Formal') {
        if (this.start_time > currentTime) {
          this.$message({
            message: '考试开始时间未到，请再耐心等待一会',
            type: 'warning'
          })
          return
        }

        if (this.start_time + 7200000 < currentTime) {
          this.$message({
            message: '考试入场时间已过，考试开始15分钟后不得再入场',
            type: 'warning'
          })
          return
        }
      }


      this.$router.replace({
        path: '/exam',
        query: {
          template_id: this.template_id,
          invigilateStatus:false
        }
      })
    },
    async checkDevice() {
      if (this.status) {
        return
      }
      this.clearAllResource()
      // 判断浏览器是否有权限
      if (!navigator || !navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        this.$message.error('浏览器不支持访问摄像头')
        return console.log('浏览器不支持访问摄像头!');
      }
      if (!navigator || !navigator.mediaDevices || !navigator.mediaDevices.getDisplayMedia) {
        this.$message.error('浏览器不支持屏幕录制')
        return console.log('浏览器不支持屏幕录制!');
      }
      this.cameraVideo = document.createElement('video');
      this.screenVideo = document.createElement('video');
      // 视频流
      const loading = this.$loading({
        lock: true,
        text: '获取摄像头权限中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let stream = await navigator.mediaDevices.getUserMedia(this.cameraOptions).catch((err) => {
        this.$message.error(`摄像头获取失败 error:${err}`)
        this.device_camera_status = false
        this.clearAllResource()
        return
      }).finally(() => {
        loading.close()
      });
      this.cameraStream = stream
      this.cameraVideo.srcObject = stream;
      this.cameraVideo.play();
      loading.close()
      // 屏幕流
      const loading1 = this.$loading({
        lock: true,
        text: '获取屏幕分享中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let stream1 = await navigator.mediaDevices.getDisplayMedia(this.screenOptions).catch((err) => {
        this.$message.error(`屏幕获取失败 error:${err}`)
        this.device_screen_status = false
        this.clearAllResource()
        return
      }).finally(() => {
        loading1.close()
      });
      this.device_camera_status = true
      if (stream1 && stream1.getVideoTracks()[0].getSettings().displaySurface !== 'monitor') {
        this.$alert('请选择分享整个屏幕', '屏幕共享失败', {confirmButtonText: '确定', type: 'error'})
        this.device_screen_status = false
        this.clearAllResource()
        return
      }
      this.device_screen_status = true
      this.screenStream = stream1
      this.screenVideo.srcObject = stream1;
      this.screenVideo.play();
      this.status = true
      loading.close()
    },
    startCheckOnline() {
      this.timer = setInterval(() => {
        if (!this.cameraStream || !this.cameraStream.active) {
          this.device_camera_status = false
          if (this.status) {
            this.clearAllResource()
          }
          this.status = false
        }
        if (!this.screenStream || !this.screenStream.active) {
          this.device_screen_status = false
          if (this.status) {
            this.clearAllResource()
          }
          this.status = false
        }
      }, 1000)
    },
    clearIdStream() {
      if (this.idStream && this.idStream.getTracks) {
        this.idStream.getTracks().forEach(track => track.stop())
        this.idStream = null
      }
      this.idVideoStream = null
    },
    clearAllResource() {
      this.clearIdStream()
      if (this.cameraStream && this.cameraStream.getTracks) {
        this.cameraStream.getTracks().forEach(track => track.stop())
      }
      if (this.screenStream && this.screenStream.getTracks) {
        this.screenStream.getTracks().forEach(track => track.stop())
      }
      this.cameraVideo = null
      this.screenVideo = null
      this.cameraStream = null
      this.screenStream = null
      this.status = false
    },
    checkStudentExam() {
      checkIsUploadTest(this.template_id, getUserId()).then((res) => {
        var result = res.data
        this.have_exam = result

      })
    }
  },
}

</script>

<style scoped>

.ready-go-exam {
  cursor: pointer;
  margin-top: 11vh;
  text-align: center;
  width: 25vw;
  height: 8.9vh;
  background-color: white;
  opacity: 1;
  border-radius: 3.484vw;
  font-size: 2vw;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 8.9vh;
  color: #0E409F;
}

.ready-img {
  width: 32px;
  height: 32px;
}

.ready-restart {
  cursor: pointer;
  border-radius: 1.664vw;
  background-color: white;
  color: #00309F;
  font-size: 2vw;
  font-family: "Microsoft YaHei";
  font-weight: bold;
  padding: 0.208vw 1vw;
  margin-left: 2.6vw;
}

.ready-text {
  font-size: 36px;
  color: white;
  font-family: 'Microsoft YaHei';
  font-weight: bold
}

.ready-root {
  width: 100vw;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-image: url("../../assets/exam_ready_bg.png");
  background-color: transparent;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

</style>